@font-face {
    font-family: retroGaming;
    src: url(../helpers/fonts/retro_gaming.ttf);
}

@font-face {
    font-family: NeuePlak;
    src: url(../helpers//fonts/neue-plak-cufonfonts/Neue\ Plak\ Regular.ttf);
}

:root{
    --header-height : 24px;
    --scrollbar-width : 5px;
    --main-color : #0555e1;
    --secondary-text-color : #6f7287;
    --secondary-button-color : #f7f6f9;
}

body, .scroll-item, .ant-modal-wrap{
    overflow-y : scroll !important;
    width: 100% !important;
}

body::-webkit-scrollbar,
.scroll-item::-webkit-scrollbar,
.ant-modal-wrap::-webkit-scrollbar{
    width: var(--scrollbar-width);
}

body::-webkit-scrollbar,
.scroll-item::-webkit-scrollbar,
.ant-modal-wrap::-webkit-scrollbar
{
    width: var(--scrollbar-width);
    background: rgb(239, 243, 246);
    height: 5px;
}
body::-webkit-scrollbar-thumb,
.scroll-item::-webkit-scrollbar-thumb,
.ant-modal-wrap::-webkit-scrollbar-thumb
{
    background-color: rgb(199, 208, 221);
    border-radius: 20px;
}

.content{
    overflow-x: hidden;
    overflow-y: hidden;
    min-height: calc(100vh - var(--header-height));
}

.main-header{
    /* background : #f7fafc !important; */
    background : #fff !important;
    border : 'none';
}

.main-content{
    /* background : #f7fafc; */
    background : #fff;
    min-height: calc(100vh - var(--header-height));
    padding-top: var(--header-height);
}

.main-menu.ant-menu{
    background: transparent;
    padding: 0 14px;
}

.main-menu.ant-menu .ant-menu-item{
    padding-left : calc(50% - 12px) !important;
    border-radius: 8px;
    display: flex;
    align-items : center;
    margin-top: 8px;
}

.main-menu.ant-menu .ant-menu-item.ant-menu-item-selected{
    background-color: var(--main-color);
    box-shadow: 0 5px 10px rgb(154 160 185/5%), 0 15px 40px rgb(166 173 201/20%);
    /* border : 1px solid red; */
}

.main-sider.ant-layout-sider .ant-layout-sider-children{
    display: flex;
    flex-direction: column;
}

.sub-menu-select{
    cursor: pointer;
}

.sub-menu-select .right-arrow{
    color: #8c8c8c;
    /* transform: translateX(-100%); */
    transition: all 0.5s;
}

.sub-menu-select:hover .right-arrow{
    color: #000;
    transform: translateX(30%);
}

.input_wrapper > input,
.input_wrapper > .ant-input-affix-wrapper{
    border-radius: 8px;
    /* border: none; */
    background: #fff;
}

.button-shadow.secondary{
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(64 68 82 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(64 68 82 / 8%) 0px 2px 5px 0px;
}

.custom-modal .ant-modal-header{
    border-radius: 6px 6px 0 0;   
}

.custom-modal .ant-modal-content{
    border-radius: 6px;
}

/* table */
.hub-table.bordered .ant-table{
    border : 1px solid #ebeef5;
    border-radius: 8px;
}

.hub-table .ant-table{
    /* border : 1px solid #f0f0f0; */
    border-radius: 8px;
}

.hub-table-list .ant-card-body{
    padding: 24px 0;
}

.hub-table-list .ant-table-pagination.ant-pagination{
    margin: 16px;
}

.hub-table .ant-table-thead > tr > th{
    background: #f7fafc;
    border-bottom : 1px solid #f5f5f5;
    color : #434343;
}

/* .hub-table .ant-table-thead > tr > th{
    background: #fff;
    border-bottom : 1px solid #f5f5f5;
    color : #434343;
} */

.hub-table .ant-table-tbody > tr > td{
    /* border-bottom: none; */
    border-bottom : 1px solid #f5f5f5;
    color: #595959;
}

.hub-table .ant-table-container table > thead > tr:first-child th:first-child{
    border-top-left-radius : 8px;
    padding-left: 20px;
    /* padding: 0 40px; */
}

.hub-table .ant-table-tbody > tr > td:first-child{
    padding-left: 20px;
    /* padding: 0 20px; */
}

.hub-table .ant-table-container table > thead > tr:first-child th:last-child{
    border-top-right-radius : 8px;
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    display: none;
}




.hub-table .action-td{
    text-align: right;
}

/* button */
.mini-button{
    font-weight: 500;
    /* box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(64 68 82 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(64 68 82 / 8%) 0px 2px 5px 0px; */
}

/* input item */
.border-input,
.border-input .ant-select-selector{
    border-radius: 6px !important;
}

.pixel-label{
    font-family: retroGaming;
}

.ticket-label{
    font-family: Poppins,sans-serif;
}

.quill-reader .ql-editor{
    padding : 12px 0;
}

.card-shadow{
    box-shadow: 0 5px 10px rgb(154 160 185/5%), 0 15px 40px rgb(166 173 201/20%);
}


.selection-card{
    box-shadow: 0 2px 4px -6px #18274b1f;
    border: 1px solid #cbd5e0 !important;
}

.selection-card.selected{
    box-shadow: 0 6px 14px -6px #18274b1f;
    border: 1px solid var(--main-color) !important;
    background: #f7fafc;
}

.selection-card:hover{
    box-shadow: 0 6px 14px -6px #18274b1f;
    background : #f7fafc;
}

.step-no{
    background:#F8F7FA;
    height:40px;
    width:40px;
    display:flex;
    align-items : center;
    justify-content : center;
    border-radius: 6px;
}

.step-no span{
    font-weight: bold;
    font-size: 20px;
    color: var(--main-color);
}

.step-label span{
    font-size: 20px;
    font-weight: bold;
    color: rgb(30, 10, 60);
}



/* transition */
.right-arrow{
    opacity: 0;
}

.menu-grouper{
    cursor: pointer;
}

.menu-grouper:hover .right-arrow{
    opacity: 1 !important;
}


.transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
}

.ease-out {
    transition-timing-function: cubic-bezier(0,0,.2,1);
}

.ant-drawer.drawer-detail-root .drawer-detail.ant-drawer-content,
.ant-drawer.drawer-detail-root .ant-drawer-content-wrapper{
    border-top-left-radius : 12px;
    border-bottom-left-radius: 12px;
}

.ant-drawer-mask.drawer-detail-mask {
    background: red;
}

@media (max-width : 768px) {
    .mobile-padding-24{
        padding : 24px;
    }

    .mobile-padding-12{
        padding : 12px;
    }
    
    .mobile-padding-left-right-24{
        padding : 0 24px;
    }
}


/* to fixed on scheduler */
.ant-row{
    display: flex !important;
}

.ant-row::before, .ant-row::after{
    display : flex !important;
}