.material-card:hover{
    box-shadow: 0 5px 10px rgb(154 160 185/5%), 0 15px 40px rgb(166 173 201/20%);
}

.row-deleted{
    opacity: 0.25;
}

.rgl-container .react-grid-item{
    border : 1.5px solid #cbd5e0;
    background : #edf2f7;
    border-radius: 8px;
}

.badge-width{
    width: 100%;
    height: 100%;
}

.width100-form-label label{
    width: 100%;
}

.approval-route-step.ant-steps .approval-route-step-icon{
    background : #e6f3ff;
    border-color: #e6f3ff;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
}